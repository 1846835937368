import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Error>
      <div>
        <h1>404: Not found</h1>
        <p>You just hit a route that doesn&#39;t exist... </p>
      </div>
    </Error>
  </Layout>
)

export default NotFoundPage

const Error = styled.div`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--beige);
  color: var(--pink);

  div {
    text-align: center;
  }
`
